import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Document = _resolveComponent("Document")!
  const _component_Notes = _resolveComponent("Notes")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_Document, {
      getSelectedCommits: _ctx.getSelectedCommits,
      ref: "doc"
    }, null, 8, ["getSelectedCommits"]),
    _createVNode(_component_Notes, {
      doc: _ctx.getDoc,
      ref: "notes"
    }, null, 8, ["doc"])
  ]))
}